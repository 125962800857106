.blogbanner {
    background-image: url('../../assets/images/blogBg.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding-bottom: 6rem !important;

    .InsideBanner {
        p {
            padding: 0rem !important;
        }
    }
}

.blogItems {
    background-image: url('../../assets/images/bgBlog1.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 2rem;

    .col-md-4 {
        cursor: pointer;
    }

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;

        text-align: center;

        background: linear-gradient(90.2deg, #5350A2 0.18%, #41649B 113.85%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;


    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        /* identical to box height, or 178% */

        align-items: center;
        text-align: center;

        color: rgba(112, 112, 112, 0.8);
    }

    .itemDesign {
        height: 480px;

        .img-div {
            height: 285px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            display: flex;
            align-items: end;
            padding-bottom: 2.5rem;

            .tagcontainer {
                button {

                    height: 29px;

                    background: rgba(255, 255, 255, 0.5);
                    /* Backgrounds/Primary */
                    border: 1px solid #FFFFFF;
                    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.25);
                    backdrop-filter: blur(3px);
                    /* Note: backdrop-filter has minimal browser support */
                    border-radius: 24px;
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    /* identical to box height */

                    color: #FFFFFF;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 14px 35px 15px;
                    gap: 10px;


                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                }


            }
        }

        .borderBottom {
            border-bottom: 1px solid #DCDCDC;
            height: 37px;
        }

        .descriptionContainer {
            h2 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 120%;
                /* identical to box height, or 22px */
                text-align: left;
                color: #101010;
            }

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150.5%;
                /* or 24px */

                text-align: left;

                color: #707070;

            }
        }
    }
}

.BlogSlider {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 2rem;
    padding-bottom: 3rem;
    background-image: url('../../assets/images/bgBlog3.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 36px;

    h1 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 50px;
        /* identical to box height, or 104% */
        letter-spacing: -0.88px;
        text-align: center;

        color: #FFFFFF;
    }

    .slick-slide {
        height: unset !important;
    }

    .slick-dots li {
        width: 40px !important;
        height: 25px !important;

        padding: 0 !important;
        cursor: pointer;
        margin: 0 !important;


        display: flex !important;
        align-items: center !important;

    }


    .post-slide {

        .Content-wrap {
            height: 650px;
            position: relative;
            margin-bottom: 2rem;
        }

        .post-content {
            background: #FFFFFF;
            border-radius: 16px;

            padding: 1rem;
            position: absolute;
            top: 57%;
            left: 25%;
        }






        h4 {
            display: inline-block;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 150%;
            /* identical to box height, or 18px */

            /* Colors/Indigo */
            color: #5856D6;
            padding-right: 10px;
        }

        .post-date {
            display: inline-block;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            /* identical to box height, or 18px */

            color: #999999;
        }

        .post-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 45px;
            leading-trim: both;
            text-edge: cap;
            letter-spacing: -1px;

            color: #101010;


        }

        .post-description {
            /* Google has been investing in AI for many years and bringing its benefits to individuals, businesses and communities. Whether it’s publishing state-of-the-art research, building helpful products or developing tools and resources that enable others, we’re committed to making AI accessible to everyone. */



            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            /* or 29px */

            color: #707070;


            /* Inside auto layout */
            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;

        }

    }

}

.custom-dots {
    bottom: 10px;
    /* Adjust the position of the dots */
}



.custom-dot {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    margin: 0 5px;
    transition: background-color 0.3s ease;

    li {
        position: relative;

        width: 38px;
        height: 25px;

        padding: 0;
        cursor: pointer;


        display: flex;
        align-items: center;
    }
}


.custom-dot:hover,
.custom-dots .slick-active .custom-dot {
    background-color: #fff;
    /* Change color when active */
    width: 20px;
    height: 20px;
}

/* Additional style for dots container */
.slick-dots.custom-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.BrowseBy {

    background-image: url('../../assets/images/bgBlog4.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 3rem;

    h2 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 50px;
        /* identical to box height, or 104% */
        letter-spacing: -0.88px;
        text-align: center;

        color: #FFFFFF;
    }

    .Design_layout {
        background: #FFFFFF;
        border: 1px solid #E3EBFF;
        border-radius: 18.8559px;
        height: 142px;
        padding: 2rem;

        h2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 31px;
            /* identical to box height, or 129% */
            display: flex;
            align-items: center;

            color: #0D0F2C;
            margin-top: 1rem;

        }

    }


}

.blogInnerbanner {
    height: 505px;
    background-image: url('../../assets/images/bannerInnerbLog.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 2rem;

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #FFFFFF;

        img {
            cursor: pointer;
        }

    }
}

.BlogDescriptioninner {
    .blogWrap {
        padding: 2rem;

        h3 {


            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 110%;
            /* identical to box height, or 29px */

            color: #101010;

        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 190%;
            /* or 27px */
            margin-top: 1rem;
            color: #707070;


        }

        ul {
            list-style: none;

            li {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150.5%;
                /* identical to  box height, or 21px */
                margin-top: 1.3rem;

                color: #707070;

                span {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 150.5%;
                    /* identical to box height, or 30px */
                    margin-right: 10px;
                    color: #101010;

                }
            }
        }

        .BlogRainbowbg {
            height: 368px;
            background-image: url('../../assets/images/BIimag2.png') !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            padding: 5rem;
            text-align: center;
            align-items: center;
            justify-content: center;

            img {}

            h3 {
                font-family: 'Mulish';
                font-style: italic;
                font-weight: 800;
                font-size: 24px;
                line-height: 130.5%;
                /* or 62px */
                text-align: center;
                color: #FFFFFF;

                span {

                    font-family: 'Mulish';
                    font-style: italic;
                    font-weight: 800;
                    font-size: 41px;
                    line-height: 150.5%;
                    /* or 62px */
                    text-align: center;

                    color: #FFFFFF;

                }

            }
        }

        .IconsBox {
            height: 112px;
            border-top: 1px solid #707070;
            border-bottom: 1px solid #707070;
            margin-top: 2rem;

            .col-md-8 {
                height: 112px;
                display: flex;
                align-items: center;
            }

            .col-md-4 {
                height: 112px;
                display: flex;
                align-items: center;
                justify-content: end;

                img {
                    margin-right: 1rem;
                }
            }

            button {
                background: #F6F6F6;
                border-radius: 24px;
                height: 52px;

                font-family: 'Mulish';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
                /* identical to box height */
                padding: 14px 35px 15px;
                gap: 10px;
                color: #101010;
                margin-right: 2rem;
                border: none;
            }
        }

        .SocialBox {
            height: 319.03px;
            margin-top: 2rem;
            background: #F6F6F6;
            border-radius: 20px;
            padding: 2rem;
            display: flex;
            align-items: center;

            .col-md-9 {
                h2 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 110%;
                    /* identical to box height, or 33px */

                    color: #101010;


                }

                h3 {

                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 150%;
                    /* identical to box height, or 30px */
                    text-transform: capitalize;

                    color: #707070;
                }

                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 150.5%;
                    /* or 30px */

                    color: #707070;
                }

                .icons {
                    img {
                        margin-right: 0.7rem;
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }

    }
}

@media (max-width: 768px) {
    .blogbanner {
        padding-bottom: 1rem !important;
    }

    .blogItems {
        padding: 1rem;

        h2 {
            font-size: 28px;
            line-height: 40px;
        }

    }

    .BlogSlider {
        .slick-slide {
            display: block !important;
        }

        margin: 0rem;
        padding: 1rem;
        padding-bottom: 2rem;

        .post-slide {
            .Content-wrap {
                height: unset;
            }

            .post-content {
                position: unset;
                height: unset;
                padding: 1rem;
                margin-bottom: 1rem;
            }

        }
    }

    .BrowseBy {
        h2 {
            font-size: 40px;
        }

        .Design_layout {
            h2 {
                font-size: 20px;
            }
        }

        padding: 1rem;

        .col-md-4 {
            margin-top: 1rem;

        }
    }

    .blogInnerbanner {
        height: 300px;
        padding: 1rem;
    }

    .BlogDescriptioninner {
        .blogWrap {
            .BlogRainbowbg {
                height: unset;
                padding: 1rem;

                h3 {
                    font-size: 19px;

                    span {
                        line-height: 120.5%;
                    }
                }

            }

            .IconsBox {
                height: unset;
                margin-top: 1rem;

                .col-md-4 {
                    justify-content: center;
                }
            }

            .SocialBox {
                height: unset;

                .col-md-3 {
                    justify-content: center;

                    display: flex;

                }
                .col-md-9{
                    text-align: center;
                }
               

            }
        }
    }
}