.carousel-container {
    width: 100%;
    overflow: hidden;
    margin: auto;
}

.carousel-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.carousel-image {
    max-width: 100%;
    height: auto;
}


.slick-slide {

    height: 140px !important;
    display: flex !important;
    align-items: center !important;

    justify-content: center;
}

.VisonImpBg {
    background-image: url('../../assets/images/BG4.png');
    background-repeat: no-repeat;
    background-position: center;
}

.VisonImpact {

    padding-bottom: 5rem !important;

    h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;
        color: #101010;
    }


    .lead {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #707070;
    }

    .text-primary {
        text-decoration: none;
    }

    .btn-visonimp {
        background: linear-gradient(108.02deg, #5350A2 -6.2%, #7FB9FD 99.49%);
        border-radius: 50px;
        width: 230px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imgNext {
        margin-left: 10px;
    }

    .vigole_img {
        background: #FFFFFF;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        border-radius: 10.2767px;
        height: 506px;
        border: 1px solid #1B16FF;
    }

}

.transforming-ideas-section {

    background: #6C8AD5;


    background-image: url('../../assets/images/BG3.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;
        color: #fff;
    }

    .card {
        border: none;
        border-radius: 10px;

        .card-body {
            padding: 20px;
            border: 1px solid #FFFFFF !important;
            backdrop-filter: blur(2px) !important;
            ;
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 12px !important;
            ;
            background: #7893d8;

            .icon {
                color: #809DFF;
                background-color: white;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
            }

            .card-title {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #fff;
                text-align: left;

            }

            .card-text {

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #fff;
                text-align: left;
            }
        }
    }

    .btn-primary {
        background-color: #fff;
        border-color: #fff;
        color: #809DFF;
        font-weight: bold;

        &:hover {
            background-color: #f8f9fa;
            border-color: #f8f9fa;
        }
    }
}

.TransIdeaMid {
    background-image: url('../../assets/images/BG6.png');
    background-repeat: no-repeat;
    background-position: center;
}

.overflowHide {
    overflow: hidden;
}

.weTrans {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
}

.text_BelowTrans {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    align-items: center;
    text-align: center;

    color: rgba(255, 255, 255, 0.8);

}

.btn-visonimpout {
    background: #fff !important;
    border-radius: 50px;
    width: 230px;
    height: 63px;
    display: flex !important;
    ;
    align-items: center !important;
    ;
    justify-content: center !important;
    ;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    border-radius: 50px !important;
    color: #101010 !important;
    border: none;

    img {
        margin-left: 10px;
    }
}

.btn-visonimpout:hover{
    box-shadow: 1px 12px 17px -4px rgba(74,144,226,1);
}


.containerTransMid {
    display: flex;
    justify-content: space-around;

    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    margin: auto;
}

.paneltd {
    flex: 1;
    padding: 20px;
    text-align: center;
}

.icon {
    font-size: 24px;
    color: #4a90e2;
}

.tittletd {

    margin-top: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    align-items: center;
    color: #101010;
    text-align: left;

}

.descriptiontd {
    font-size: 16px;
    margin-top: 15px;
    color: #666;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    align-items: center;

    color: #707070;
    text-align: left;
}

.headerT {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    /* identical to box height, or 175% */

    align-items: center;
    text-align: center;

    color: #101010;



}

.SmallSub {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */

    align-items: center;
    text-align: center;

    color: rgba(112, 112, 112, 0.8);
    width: 75%;
    margin: auto;
}

.WrapContainerTrans {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    height: 480px;

    .Img-container {
        height: 261px;


    }

    .Img_hover {
        height: 261px;
        background: #EEF2F9;
        border-radius: 3px;
        width: 100%;
        transition: height 0.5s ease-in-out;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.paneltd:hover .Img_hover {
    height: 220px;
    background: linear-gradient(180deg, #CCCFFA 0%, #D1E9F7 100%);
}

.tech-stack-container {
    position: relative;
    background-color: #f8f9fa;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .Fuel-head {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;


        align-items: center;
        text-align: center;

        background: linear-gradient(90.2deg, #5350A2 0.18%, #41649B 113.85%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;


    }

    .lead {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;

        align-items: center;
        text-align: center;

        color: rgba(112, 112, 112, 0.8);

    }

    .tech-logo-wrapper {
        background: white;
        border-radius: 8px;

    }

    .tech-logo {
        max-width: 100%;
        height: auto;
        width: 80px;
    }

    @media (min-width: 992px) {
        .col-lg-1 {
            flex: 0 0 10%;
            //   width: 10%;
        }
    }
}

.tech-stack-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-size: cover;
    z-index: -1;
    opacity: 0.1;
    /* Adjust the opacity as needed */
}

.font-weight-bold {
    font-weight: 700 !important;
}

.TransIdeaTech {
    background: #6C8AD5;
    border-radius: 36px;
    background-image: url('../../assets/images/BG2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;
        color: #fff;
        text-align: center;
    }

    .Design_box {

        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid rgba(255, 255, 255, 0.27);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(32px);
        height: 644px;
        border-radius: 12px;
        transition: background-color 0.5s ease-in-out;

        &:hover {
            background-color: rgba(255, 255, 255, 0.33);
        }

    }

    .DES2 {
        height: 540px;
    }

    .mr-right {
        margin-right: 5%;
    }

    .card-body {
        padding: 20px;

        ;

        border-radius: 12px !important;
        ;

        img {
            border-radius: 20px;
        }


        .icon {
            color: #809DFF;

            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        .card-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            text-align: left;


            color: #FFFFFF;

        }

        .card-text {

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            letter-spacing: 0.3px;

            color: rgba(255, 255, 255, 0.8);
        }
    }

}

.AreReayImg {

    background-image: url('../../assets/images/BG1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

}

.AreUReady {


    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    height: 327px;

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 72px;

        text-align: center;

        color: #5350A2;
    }

    .btn-visonimp {
        background: linear-gradient(108.02deg, #5350A2 -6.2%, #7FB9FD 99.49%);
        border-radius: 50px;
        width: 230px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;

        .imgNext {
            margin-left: 10px;
        }
    }
}

.page-container {


    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-form-container {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    background: #6C8AD5;
    display: flex;
    align-items: center;
    border-radius: 36px;
    justify-content: center;
    height: 220px;



}



.contact-header {

    color: white;
    padding: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;
        text-align: center;

        color: #FFFFFF;
    }

    p {

        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 166.5%;
        text-align: center;

        color: #FFFFFF;
        display: flex;
        align-items: center;

        i {
            font-size: 30px;
            margin-right: 5px;
        }

    }
}

.contact-header h2 {
    margin: 0;
}

.contact-header p {
    margin: 0;

}

.form-control.is-invalid {
    border-color: #dc3545;
}

.invalid-feedback {
    display: block;
}

.container {
    padding: 2rem;
}

label {
    font-weight: bold;
}

.text-center .btn {
    background: linear-gradient(90deg, rgba(72, 61, 139, 1) 0%, rgba(123, 104, 238, 1) 100%);
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 20px;
    color: #fff;
    font-size: 1.1rem;
}

.text-center .btn:hover {
    background: linear-gradient(90deg, rgba(123, 104, 238, 1) 0%, rgba(72, 61, 139, 1) 100%);
}


.form_box {
    background: #FFFFFF;
    box-shadow: 0px 69px 42px rgba(200, 200, 200, 0.25);
    border-radius: 20px;
    margin-top: -3rem !important;

    .form-control {
        appearance: auto !important;
    }


    label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        text-transform: capitalize;
        color: #101010;
        margin-bottom: 10px;

    }

    input {
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 50px;
        height: 60px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-transform: lowercase;

        color: rgba(112, 112, 112, 0.5);
        padding: 28px;


    }

    select {
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 50px;
        height: 60px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-transform: lowercase;

        color: rgba(112, 112, 112, 0.5);
        padding: 0px;
        padding-left: 2rem !important;


    }

    textarea {
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 50px;
        height: 180px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-transform: lowercase;

        color: rgba(112, 112, 112, 0.5);
        padding: 28px;
    }
}

.btn-visonimpnew {
    background: linear-gradient(108.02deg, #5350A2 -6.2%, #7FB9FD 99.49%);
    border-radius: 50px !important;
    width: 230px;
    height: 63px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: none;

    .imgNext {
        margin-left: 10px;
    }
}

.Gradient {
    height: 100px; // Height of the gradient


}

.Box_shadowDiv {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    width: 100%;
    height: 20px;
}

.bannerLinear {}

.bannerSection {
    height: 775px;
    border-radius: 40px;
    background-image: url('../../assets/images/Vbanner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .desKick {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 34px;
        /* or 170% */
        
        color: #FFFFFF;
        
    }

    padding-left: 2rem !important;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.45) -20px 55px 50px -50px;
    margin: 2rem;
    margin-top: -3%;

    a {
        text-decoration: none;
    }

    .Banner_heading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;     
        color: #FFFFFF;
        
    }

    button {
        border: none;
    }

    .Images_container {
        height: 200px;
    }

    .ps-53 {}

    .slick-slide {
        justify-content: start !important;
        padding-left: 0.5rem;
        height: 100px !important;
    }

    .Text-effect {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 48px;

        text-align: left;
        color: #FFFFFF;

    }

    .iconLeft {
        width: 10px;
        height: 31px;
        display: inline-block;
        background: #5956E9;
        border-radius: 10px;
    }

    .img1 {
        position: relative;
        top: 94%;
        right: 7%;
    }

    .img2 {
        position: relative;
        top: 43%;
        right: 7%;
    }

    .img3 {}

    .img4 {
        position: relative;
        top: 43%;
        left: 10%;
    }

    .img5 {
        position: relative;
        top: 96%;
        left: 11%;
    }

    .Out-side-btn {
        background: linear-gradient(108.02deg, #5350A2 -6.2%, #7FB9FD 99.49%) !important;
        color: #fff !important;
    }

    .Btn-Outside {
        padding-right: 0rem;
    }

}

.GetInTOuchBg {

    background-image: url('../../assets/images/BG7.png');
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 4rem;
    padding-bottom: 7rem;
    background-size: 100% 100%;
}


@media (max-width: 768px) {
    .SmallSub {
        width: 100%;
    }

    .VisonImpact {

        .vigole_img {
            height: 400px;
        }

        padding-bottom: 0rem !important;

    }

    .Mobile_Margin {
        margin: auto !important;
    }

    .mobile_img5 {
        width: 100% !important;
    }

    .mobile-img7 {
        height: 200px;
    }

    .WrapContainerTrans {
        height: 520px;
    }

    .AreUReady {
        p {
            font-size: 25px;
            line-height: 46px;
        }

    }

    .contact-header p {
        font-size: 12px;
        line-height: 130.5%;
    }

    .form_box {
        padding: 1.5rem !important;
    }

    .bannerLinear {}

    .bannerSection {
        display: none;
    }

    .bannerMobile {
        background-image: url('../../assets/images/BG3.png');
        background-repeat: no-repeat;
        background-position: center;

        .Banner_headingmobile {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
            color: #FFFFFF;
            text-align: center;
        }

        .Text-effect {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 27px;
            line-height: 27px;

            text-align: center;
            color: #FFFFFF;

        }

        .iconLeft {
            width: 10px;
            height: 20px;
            display: inline-block;
            background: #5956E9;
            border-radius: 10px;
        }


        button {
            border: none;
        }
    }

    .containerTransMid {
        width: unset;
    }

    .tech-stack-container {

        padding-top: 0rem;
        padding-bottom: 0rem;

        .Fuel-head {
            font-size: 34px;
            line-height: 40px;
        }

        .Mobile_width {
            width: 100% !important;
            padding: 10px;
        }
    }

}



#spin {}

#spin:after {
    content: "";
    animation: spin 30s linear infinite;
}

.tech-slide {

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 119.14px;

    background: #FFFFFF;

    box-shadow: 0 2.28832px 8.5091px #00000026;
    border-radius: 6.99428px;

    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 119px !important;
    display: flex !important;
}

@keyframes spin {
    0% {
        content: "Web App Development";
    }

    12% {
        content: "Mobile App Development";
    }

    24% {
        content: "Agile & DevOps";
    }

    36% {
        content: "UI/UX Design";
    }

    48% {
        content: "Digital Marketing";
    }

    60% {
        content: "Blockchain development";
    }

    72% {
        content: "AR and Mixed Reality Lab";
    }

    84% {
        content: "Artificial Intelligence";
    }

    100% {
        content: "Internet of Things";
    }



}


@media (min-width: 1200px) {
    .bannerMobile {
        display: none;
    }
}
.form-control.is-invalid, .was-validated .form-control:invalid{
    background: none !important;
}