/* Footer Styles */
.FooterStyle {
  background-color: #FFF;
  /* Dark background */
  border: 1px solid #E2E2E2;

  .routlist {
    
  }

  .privacyroutes {
    display: flex;
    justify-content: center;

    a {
      color: #707070;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 175%;
      text-decoration: none;
      margin-left: 1rem;

      &:hover {
        color: #000;
      }
    }
  }

  .footerdes {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* or 200% */
    letter-spacing: -0.204545px;

    color: #1E2833;

    mix-blend-mode: normal;
    opacity: 0.5;
  }

  h5 {
    color: #101010;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 129%;
  }

  ul {
    li {
      color: #707070;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 175%;
      margin-bottom: 1.1rem;


      a {
        text-decoration: none;
        color: inherit;

      }

      a:hover {
        color: #000;
      }
    }
  }

  .LogoStyle {
    width: 100%;
    height: 57px;
  }

  .socialIcon {

    width: 40px;
    /* Diameter of the circle */
    height: 40px;
    /* Diameter of the circle */
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }

  }

  .social-icon:hover {
    color: #000;
    /* Change color on hover */
    background-color: #ddd;
    /* Change background color on hover */
  }


}




ul.list-unstyled {
  line-height: 1.6;
  /* Adjust line height for better readability */
  margin-top: 1.1rem;
}

.Footer-rights {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 129%;

  text-align: center;

  color: #5350A2;
}

.Small-box {
  background: #5350A2;
  width: 25%;
  height: 20.62px;
  clip-path: polygon(2% 0%, 98% 0%, 100% 99%, 0% 100%);
  border: 1px solid #5350A2;
  position: relative;
  top: 1px;
}

.Big-box {
  background: #5350A2;

  height: 47.62px;
  border: 1px solid #5350A2;
}

/* Add more specific styles for spacing and mobile responsiveness */
@media (max-width: 768px) {
  footer .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .LogoStyle {
    justify-content: center;
    display: flex;
  }

  .Small-box {
    width: 160px !important;

  }
}