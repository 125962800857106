.careerbanner {
  background-image: url("../../assets/images/careerbg.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 8rem !important;
  display: flex;
  align-items: end;
  position: relative;
  overflow: hidden;
}
.glass-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background: linear-gradient(
      to top,
      rgba(19, 23, 64, 0.7) 0%,
      // Darker and less transparent at the bottom
      rgba(19, 23, 64, 0.4) 50%,
      // More transparent in the center
      rgba(19, 23, 64, 0.4) 100% // Darker and less transparent at the top
    );
    backdrop-filter: blur(0) brightness(1);
    transition: height 2s ease;
  }

  .glass-effect .glass-overlay {
    height: 100%;
  }

.WhyWork {
  background: #f5f8ff;

  padding: 3rem;
  padding-right: 0rem;

  .DetailsContainer {
    padding-left: 4rem;
    padding-right: 0rem;

    display: flex;
    align-items: center;

    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 70px;
      display: flex;
      align-items: center;
      background: linear-gradient(90.2deg, #5350a2 0.18%, #41649b 113.85%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
      display: flex;
      align-items: center;

      color: rgba(112, 112, 112, 0.8);
    }
  }
}

.JoinVikgole {
  background-image: url("../../assets/images/BgJoin.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;

  .col-md-4 {
    cursor: pointer;
  }

  .mainHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;

    text-align: center;

    background: linear-gradient(90.2deg, #5350a2 0.18%, #41649b 113.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .DetailHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */
    width: 60%;
    margin: auto;
    align-items: center;
    text-align: center;

    color: rgba(112, 112, 112, 0.8);
  }

  .designlayout {
    background: #ffffff;
    border: 1px solid rgba(112, 112, 112, 0.14);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    min-height: 340px;
    padding: 1rem;
    padding-top: 3rem;
    margin-bottom: 16px;

    .op8 {
      opacity: 0.8;
    }

    .op7 {
      opacity: 0.7;
    }

    .op6 {
      opacity: 0.6;
    }

    .justpost {
      width: 120px;
      height: 22px;
      background-image: url("../../assets/images/BgJust.png");
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      left: 1px !important;
      bottom: 48px !important;
      background-size: 100% 100%;
      background-size: 100% 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
      display: flex;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #fff;
    }

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      /* identical to box height, or 22px */
      display: flex;
      align-items: center;

      color: #101010;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150.5%;
      /* or 21px */

      color: #707070;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;

      color: #101010;
    }

    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 166.5%;
      /* identical to box height, or 27px */

      color: #101010;

      span {
        color: #5350a2;
      }
    }
  }
}

.PerfectPostion {
  height: 650px;
  background: #fff;
  display: flex;
  align-items: end;

  .PostionContainer {
    height: 459px;
    background-image: url("../../assets/images/bgPos.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    position: relative;

    .PositionText {
      padding-left: 4rem;

      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 56px;
        line-height: 80px;
        display: flex;
        align-items: center;
        letter-spacing: -2.688px;
        color: #ffffff;
      }

      button {
        border: none;
      }
    }

    .imgPosLarge {
      position: absolute;
      position: absolute;
      bottom: 0;
    }
  }
}

.CareerInfo {
  background: #f5f7fa;
  height: 257px;
  padding: 3rem;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    color: rgba(112, 112, 112, 0.5);

    img {
      cursor: pointer;
    }
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    color: #707070;

    img {
      margin-right: 4px;
    }

    margin-top: 2rem;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height, or 120% */
    letter-spacing: -0.01em;

    color: #101010;
    margin-top: 2rem;
  }

  .btn-visonimp {
    background: linear-gradient(108.02deg, #5350a2 -6.2%, #7fb9fd 99.49%);
    border-radius: 50px;
    width: 230px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: auto;
  }

  .imgNext {
    margin-left: 10px;
  }
}
.cursorpointer {
  cursor: pointer;
}
.careerDescription {
  padding: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #fff;

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1d2026;
    margin-top: 1rem;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    letter-spacing: -0.01em;

    color: #707070;
    margin-top: 1rem;
  }

  ul {
    margin-top: 1rem;

    li {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 40px;
      /* or 286% */
      letter-spacing: -0.01em;

      color: #707070;
    }
  }

  .TickList {
    list-style: none;
    /* Remove default bullets */
    padding-left: 0;

    li {
      padding-left: 24px;
      /* Space for icon */
      background: url("../../assets/images/Check.png") no-repeat left center;
      /* URL to your icon image */
      background-size: 20px;
    }
  }

  .btn-visonimp {
    background: linear-gradient(108.02deg, #5350a2 -6.2%, #7fb9fd 99.49%);
    border-radius: 50px;
    width: 230px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .imgNext {
    margin-left: 10px;
  }
}
.modal-pad {
  z-index: 2222222 !important;
}
.modal-lg-cust {
  max-width: 58rem !important;
  z-index: 9999;
}

.Form-modal {
  border-radius: 15px !important;
  overflow: hidden;

  .popupFormSection {
    .Close_design {
      cursor: pointer;
    }

    padding: 0;

    overflow: hidden;

    .application-form-container {
      .heading-container {
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;

        border-bottom: 2px solid #7070704f;

        h2 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;

          letter-spacing: -0.01em;

          color: #101010;
          display: flex;
          justify-content: space-between;

          img {
            cursor: pointer;
          }
        }

        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          /* identical to box height, or 200% */
          display: flex;
          align-items: center;
          color: #707070;
        }
      }

      .formDesign {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .form-label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */
        text-transform: capitalize;
        margin-top: 1rem;
        color: rgba(112, 112, 112, 0.8);
      }

      input {
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 20px;
        height: 46px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        text-transform: lowercase;

        color: rgba(112, 112, 112, 0.5);
      }

      .drag-drop-area {
        cursor: pointer;
        height: 88px;
        border: 0.743902px dashed rgba(0, 0, 0, 0.25);
        border-radius: 7.43902px;
        margin-bottom: 4px;

        .col-7 {
          h5 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #101010;
          }

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            /* identical to box height */

            color: #707070;
          }
        }

        .col-2 {
          height: 100%;
          align-items: center;
          img {
            height: 35px;
          }
        }

        .col-3 {
          height: 100%;
          align-items: center;
          button {
            height: 28.85px;

            background: #fbfdfe;
            border: 0.743902px solid rgba(15, 145, 210, 0.7);
            border-radius: 3.71951px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 7.43902px;
            line-height: 11px;
            text-transform: uppercase;

            color: #0f91d2;
          }
        }

        .file-name {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          /* identical to box height */

          color: #101010;
        }
      }

      .button-group {
        background: #f5f7fa;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-radius: 15px;

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height */

          color: #101010;
          padding: 0;
          padding-top: 1rem !important;
        }

        .btn-visonimp {
          background: linear-gradient(108.02deg, #5350a2 -6.2%, #7fb9fd 99.49%);
          border-radius: 50px;
          width: 200px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
        }

        .imgNext {
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .careerbanner {
    padding-bottom: 4rem !important;
  }

  .WhyWork {
    padding: 1rem;

    .DetailsContainer {
      padding: 1rem;

      h1 {
        font-size: 32px;
        line-height: 49px;
      }

      p {
      }
    }

    .ChooseImg {
      padding: 0rem;
    }
  }

  .JoinVikgole {
    padding: 1rem;

    .mainHead {
      font-size: 35px;
      line-height: 41px;
    }

    .DetailHead {
      width: 100%;
    }

    .designlayout {
      padding-top: 1rem;
      padding-top: 2rem;
      margin-top: 1rem;
      height: unset;

      .justpost {
        bottom: 32px !important;
      }
    }
  }

  .PerfectPostion {
    height: unset;
    display: block;

    .PostionContainer {
      height: unset;

      .PositionText {
        padding: 1rem;

        h2 {
          font-size: 31px;
          line-height: 40px;
          text-align: center;
        }

        button {
          margin: auto;
        }
      }

      .mobileAdjust {
        display: flex;
        justify-content: center;

        .imgPosLarge {
          position: relative;
          height: 300px;
        }
      }
    }
  }

  .CareerInfo {
    padding: 1rem;
    height: unset;

    h3 {
      font-size: 12px;

      img {
        width: 18px;
      }

      margin-top: 1rem;
    }

    h1 {
      font-size: 20px;
      line-height: 24px;
      margin-top: 1rem;
    }
  }

  .careerDescription {
    padding: 1rem;

    .btn-visonimp {
      margin: auto;
    }
  }

  .Form-modal {
    .popupFormSection {
      .application-form-container {
        .heading-container {
          padding: 1rem;

          h2 {
            font-size: 16px;
          }

          h3 {
            font-size: 11px;

            img {
              width: 17px;
            }
          }
        }

        .formDesign {
          margin-top: 1rem;
        }
      }
    }
  }
}
