.privacy-policy-container {
  
   
    background: #F5F8FF;
    padding: 3rem;

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 20px;
        /* identical to box height, or 77% */
        display: flex;
        align-items: center;

        color: #000000;
    }

    .section {
        margin-bottom: 2rem;

        h3 {
            margin-top: 1rem;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 40px;
            /* or 250% */
            display: flex;
            align-items: center;

            color: #000000;

        }

        p {
            margin-bottom: 1rem;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 40px;
            /* or 286% */
            display: flex;
            align-items: center;

            color: #707070;

        }

        ul {
            margin: 0;
            padding-left: 1.5rem;
            list-style-type: disc;

            li {
                margin-bottom: 0.5rem;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 40px;
                /* or 286% */

                align-items: center;

                color: #707070;


                ul {
                    padding-left: 1rem;
                    margin-top: 0.5rem;
                    list-style-type: disc;

                    li {
                        margin-bottom: 0.5rem;
                        margin-bottom: 0.5rem;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 40px;
                        /* or 286% */

                        align-items: center;

                        color: #707070;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 1rem;
    }
}