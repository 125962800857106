.terms-of-use {
    background: #F5F8FF;
    padding: 3rem;

    h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        /* or 250% */
        display: flex;
        align-items: center;

        color: #000000;
        margin-top: 1rem;
    }

    h2 {

        margin-top: 1rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 20px;
        /* identical to box height, or 77% */
        display: flex;
        align-items: center;

        color: #000000;


    }

    h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 40px;
        /* or 250% */
        display: flex;
        align-items: center;

        color: #000000;
    }

    h5 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        /* or 250% */
        display: flex;
        align-items: center;

        color: #000000;
        margin-top: 1rem;
    }

    section {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        /* or 286% */
        display: flex;
        align-items: center;

        color: #707070;

    }

}
@media (max-width: 768px) {
    .terms-of-use{
        padding: 1rem;
        section {
            padding-top: 0rem;
            padding-bottom: 0rem;
        }
    }
}