.ServiceBanner {
  height: 592px;
  border-radius: 20px;
  background-image: url("../../assets/images/ServicesBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: end;
  padding-bottom: 3rem;
  position: relative;
  overflow: hidden;

  .glass-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background: linear-gradient(
      to top,
      rgba(19, 23, 64, 0.7) 0%,
      // Darker and less transparent at the bottom
      rgba(19, 23, 64, 0.2) 50%,
      // More transparent in the center
      rgba(19, 23, 64, 0.7) 100% // Darker and less transparent at the top
    );
    backdrop-filter: blur(2px) brightness(1);
    transition: height 2s ease;
  }

  &.glass-effect .glass-overlay {
    height: 100%;
  }

  .InsideBanner {
    position: relative;
    z-index: 2;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 75px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }
}

.WePropel {
  padding-top: 4rem;
  padding-bottom: 4rem;

  background-image: url("../../assets/images/PropBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  .col-md-5,
  .col-md-7,
  .col-md-4 {
    cursor: pointer !important;
  }

  .Propel_content {
    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 75px;
      display: flex;
      align-items: center;
      text-align: center;

      background: linear-gradient(191.46deg, #6f6cc4 -0.15%, #353285 91.37%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .Desbox {
      height: 478px !important;

      .card-text {
        height: 100px;
      }
    }

    .Design_box {
      background: #ffffff;
      border: 1px solid #e3ebff;
      border-radius: 18.8559px;
      height: 448px;
      overflow: hidden;
      position: relative;
      transition: all 1s;

      .card-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        /* or 129% */
        display: flex;
        align-items: center;

        color: #101010;
      }

      .card-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */
        display: flex;
        align-items: center;

        color: #707070;

        overflow: hidden;
      }

      .mobile_img5 {
        width: 100%;
        border-radius: 10px;
        height: 210px;
        transition: transform 0.3s ease-in-out; // Smooth transition effect
        transform-origin: center center;
      }

      &:hover {
        .mobile_img5 {
          transform: scale(1.1);
        }
      }
    }
  }
}

.TransformBusiness {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url("../../assets/images/BGtb.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  .Ds-vikgole {
    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 65.625px;
      line-height: 80px;
      /* or 122% */
      display: flex;
      align-items: center;
      letter-spacing: -2.688px;

      color: #ffffff;
    }

    .btn-visonimpout {
      background: #fff !important;
      border-radius: 50px;
      width: 230px;
      height: 63px;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      border-radius: 50px !important;
      color: #101010 !important;

      img {
        margin-left: 10px;
      }
    }
    .btn-visonimpout:hover{
      box-shadow: 1px 12px 17px -4px rgba(74,144,226,1);
  }
  }
}

.headerTBt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: lowercase;

  background: linear-gradient(191.46deg, #6f6cc4 -0.15%, #353285 91.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center !important;
  padding: 0 4rem;
}

.TransIdeaMid {
  background-size: 100% 100%;

  .NewDesDiv {
    background: rgba(255, 255, 255, 0.17);
    border: 4px solid #ffffff;
    box-shadow: 0px 4px 24px 6px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    height: 189px;

    a {
      text-decoration: none;
    }

    .designAre {
      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;

        display: flex;
        align-items: center;

        background: linear-gradient(90deg, #22005d 0%, #4700c3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 31px;
        /* or 194% */
        display: flex;
        align-items: center;

        color: #707070;
      }
    }

    .btn-design {
    }
  }
}

.DetailBanner {
  padding: 2rem;
  text-align: center;
  padding-bottom: 0rem;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 150px;
    line-height: 106.9%;
    /* or 160px */
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    color: #212121;
  }
}

.Image_Ds {
  width: 100%;
}

.ServiceImpact {
  background: #f5f8ff;
  background-image: none;
  height: unset;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;

    background: linear-gradient(191.46deg, #6f6cc4 -0.15%, #353285 91.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .serviceBtnimpact {
    height: 46px;
    border: none;
  }
}

.YourTrusted {
  padding: 2rem;

  .Trustedpatner {
    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 140%;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: #101010;
      margin-top: 2rem;
    }

    .desp {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      /* or 188% */
      letter-spacing: 0.323077px;

      color: #707070;

      mix-blend-mode: normal;
      opacity: 0.8;
      padding-right: 5rem;
    }

    .item_container {
      height: 112px;
      margin-top: 1rem;
      background: #ffffff;
      transition: box-shadow 0.3s ease;

      .col-2 {
        img {
          height: 64px;
        }
      }

      .col-8 {
        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 140%;
          /* identical to box height, or 28px */
          display: flex;
          align-items: center;
          letter-spacing: 0.15px;

          color: #101010;

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            /* or 22px */
            display: flex;
            align-items: center;
            letter-spacing: 0.5px;

            color: #707070;
          }
        }
      }

      &:hover {
        box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.02),
          0px 16px 24px rgba(96, 97, 112, 0.12);
      }
    }
  }
}

.CompressiveAdd {
  padding: 2rem;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;

    align-items: center;
    text-align: center;

    background: linear-gradient(191.46deg, #6f6cc4 -0.15%, #353285 91.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .col-md-4 {
    margin-top: 3rem;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 110%;
      /* identical to box height, or 22px */

      color: #101010;
      margin-top: 1rem;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150.5%;
      /* or 24px */
      text-align: center;
      margin-top: 1rem;

      color: #707070;
    }
  }
}

.HarnesingAI {
  background: rgba(0, 0, 0, 0.89);

  .col-md-8 {
    padding: 4rem;
    padding-left: 7rem;

    h2 {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 500;
      font-size: 46px;
      line-height: 66px;
      /* or 143% */
      display: flex;
      align-items: flex-end;

      color: #ffffff;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      /* or 180% */

      background: linear-gradient(
        166.44deg,
        #ffffff 30.83%,
        rgba(217, 217, 217, 0.3) 131.63%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .col-md-4 {
    align-items: end;
    display: flex;

    img {
      width: 100%;
    }
  }
}

.OurFocusServices {
  padding: 2rem;
  background-image: url("../../assets/images/BgMobileCom.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 4rem;

  h2 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: -0.88px;
    color: #ffffff;
    text-align: center;
    margin-top: 1rem;
  }
  .designLayout2 {
    height: 190px !important;
  }
  .designLayout {
    height: 173px;
    background: #ffffff;
    border: 1px solid #e3ebff;
    border-radius: 18.8559px;
    margin-top: 1rem;
    padding: 2rem;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      /* identical to box height, or 129% */
      display: flex;
      align-items: center;

      color: #0d0f2c;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* or 171% */
      display: flex;
      align-items: center;

      color: #0d0f2c;
    }
  }
}

@media (max-width: 768px) {
  .ServiceBanner {
    height: 300px;
    padding-bottom: 0rem;

    .InsideBanner {
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .WePropel {
    .Propel_content {
      h2 {
        font-size: 22px;
        line-height: 36px;
      }

      .Desbox {
        .card-text {
          height: unset;
        }
      }

      .Design_box {
        height: unset !important;

        .card-body {
          padding: 15px;
        }
      }
    }
  }

  .TransformBusiness {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .Ds-vikgole {
      h2 {
        font-size: 31.625px;
        line-height: 38px;
        text-align: center;
      }

      .btn-visonimpout {
        margin: auto;
      }
    }
  }

  .headerTBt {
    padding: 0 0rem;
    font-size: 30px;
    line-height: 42px;
  }

  .NewDesDiv {
    height: unset !important;
  }

  .ServiceImpact {
    h1 {
      font-size: 28px;
      line-height: 45px;
    }

    .serviceBtnimpact {
      margin: auto;
    }
  }

  .DetailBanner {
    h1 {
      font-size: 50px;
    }

    padding: 1rem;

    .mt-4 {
      img {
        width: 100%;
      }
    }
  }

  .YourTrusted {
    padding: 1rem;

    .Trustedpatner {
      h2 {
        font-size: 27px;
        line-height: 127%;
        margin-top: 0rem;
      }

      .item_container {
        height: unset;
        padding-left: 2rem;

        .col-2 {
          img {
            height: 64px;
            width: 64px;
          }
        }
      }
    }
  }

  .CompressiveAdd {
    padding: 1rem;

    h2 {
      font-size: 29px;
      line-height: 42px;
    }

    .col-md-4 {
      margin-top: 1rem;
    }
  }

  .HarnesingAI {
    .col-md-8 {
      padding: 2rem;

      h2 {
        font-size: 29px;
        line-height: 46px;
      }

      p {
        font-size: 15px;
        line-height: 32px;
      }
    }
  }

  .OurFocusServices {
    padding: 1rem;
    .designLayout2 {
      height: unset !important;
    }

    h2 {
      font-size: 35px;
      line-height: 40px;
    }

    .designLayout {
      height: unset;
      padding: 1rem;
    }
  }
}
