
.stickynav{
  position: sticky !important;
  top: 0 !important;
  z-index: 543543 !important;
}.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  /* Set background color */
  color: #333;
  /* Set text color */
  
}

.logo img {
  height: 50px;
  /* Adjust size as necessary */
}

.nav-links a,
.social-links a {
  color: #333;
  text-decoration: none;
  margin: 0 15px;
  font-size: 16px;
}

.social-links a {
  font-size: 20px;
  /* Larger icons for social links */
}

.nav-links a:hover,
.social-links a:hover {
  color: #5746EC;
  /* Highlight color on hover */
}
.x-app{
  opacity: 0.5;
  &:hover{
    opacity: 1;
  }
}
.socialIcon {

  width: 40px;
  /* Diameter of the circle */
  height: 40px;
  /* Diameter of the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(1);
  transition: filter 0.3s ease-in-out;
  

  img {
    width: 24px;
    height: 24px;
  }
  &:hover{
    filter: grayscale(0);
    opacity: 1;
  }

}

.nav-link {
  font-family: 'Inter';
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px;
  color: #381E72;
}

.navbar-nav {
  .nav-item {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
  }

  .active {
    font-family: 'Inter';
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 17px !important ;
    display: flex;
    align-items: center !important;
    color: #381E72 !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    width: 86%;
    display: flex;
    justify-content: center;
  }
}