.ContactPageBanner {
    height: 592px;
    background-image: url('../../assets/images/contactBg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 20px;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    padding-bottom: 3rem;
    position: relative;
    overflow: hidden;

    .InsideBanner {
        position: relative;
        z-index: 2;
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 62px;
            display: flex;
            align-items: center;
            text-align: center;
            padding: 0px 14rem;
            color: #FFFFFF;
        }
    }


}

.GoogleAddress {

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: #fff;

    .mapContainer {
        height: 500px;
        border: 4px solid #FFFFFF;
        filter: drop-shadow(0px 3.60531px 12.6186px rgba(0, 0, 0, 0.06));
        border-radius: 18.0266px 0px 0px 18.0266px;
        overflow: hidden;
    }

    .designlaout {
        background: #FFFFFF;
        border: 1px solid rgba(112, 112, 112, 0.14);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        border-radius: 14px;
        height: 212px;
        padding: 1.5rem;

        img {
            float: right;
        }
        .img-con{
            height: 30px;
        }

        h2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 110%;
            /* identical to box height, or 22px */
            display: flex;
            align-items: center;

            color: #101010;
        }

        h3 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 166.5%;
            /* identical to box height, or 27px */
            text-align: left;
            color: #101010;
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150.5%;
            /* or 21px */
           text-align: left;
            color: #707070;
        }

    }

    h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        /* identical to box height */


        background: linear-gradient(191.46deg, #6F6CC4 -0.15%, #353285 91.37%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: center;
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        align-items: center;
        text-align: center;

        display: flex;

        color: #707070;

    }
}

@media (max-width: 768px) {
    .ContactPageBanner {
        height: 300px
    }

    .GoogleAddress {
        padding: 1rem;

        h3 {
            font-size: 28px;
            line-height: 35px;
        }

        p {
            display: block;
        }
    }

    .ContactPageBanner {

        padding-bottom: 0rem;

        .InsideBanner {
            p {
                font-size: 22px;
                line-height: 32px;
                padding: 0;
            }
        }
    }

}