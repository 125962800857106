.Aboutbanner {
  background-image: url("../../assets/images/aboutUsBg.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 8rem !important;
  display: flex;
  align-items: end;
  position: relative;
  overflow: hidden;
}

.ourJourney {
  padding: 4rem 6rem;
  background-image: url("../../assets/images/AbBg1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  .InsideJourney {
    background: #ffffff;
    border: 1px solid #e3ebff;
    border-radius: 18.8559px;
    height: 640px;

    h2 {
      /* Our journey from inception to innovation */

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 75px;
      /* identical to box height */

      align-items: center;
      text-align: center;

      background: linear-gradient(191.46deg, #6f6cc4 -0.15%, #353285 91.37%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    p {
      /* Born out of a passion for technology and a deep understanding of business needs, Vikgol has emerged as a leading software development company. Since our inception, we've been committed to delivering innovative solutions that drive growth and success for our clients. With a proven track record in fintech, edtech, and other industries, we have consistently exceeded expectations and built lasting partnerships. */

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* or 175% */

      align-items: center;
      text-align: center;

      color: #707070;
    }

    img {
      width: 100%;
      border-radius: 11px;
    }
  }
}

.OurPromiseSection {
  height: 600px;
  background-image: url("../../assets/images/AbBg2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  padding: 3rem 3rem;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .PromiseDesign {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(12px);
    height: 378px;
    padding: 2.5rem;

    h3 {
      margin-top: 1rem;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;

      color: #ffffff;
    }

    p {
      margin-top: 1rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.chooseVikgole {
  height: 650px;
  background-image: url("../../assets/images/AbBg3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 3rem;

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    background: linear-gradient(191.46deg, #6f6cc4 -0.15%, #353285 91.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .ChooseVikgoleDesign {
    background: #ffffff;
    border: 1px solid #e3ebff;
    border-radius: 18.8559px;
    height: 428px;
    padding: 2rem;
    overflow: hidden;
  }

  h3 {
    margin-top: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    align-items: center;
    color: #0d0f2c;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #707070;
  }

  .img-Det {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out; // Smooth transition effect
    transform-origin: center center;
  }
}
.ChooseVikgoleDesign:hover .img-Det{
    transform: scale(1.1);
}

.MyTeam {
  background-image: url("../../assets/images/bgMyteam.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 3rem;
  padding-bottom: 5rem;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    /* identical to box height */

    align-items: center;
    text-align: center;

    background: linear-gradient(191.46deg, #6f6cc4 -0.15%, #353285 91.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .DesignLayout {
    background: #65558f;
    border-radius: 7.39583px;
    height: 520.12px;
    overflow: hidden;

    &:hover {
      .text-details {
        .det1 {
        }

        .det2 {
          display: block; // Show det2 on hover
        }
      }
      .img-container {
        height: 265px !important;
      }
      .img-person {
        filter: grayscale(0) !important;
        transform: scale(1.06);
      }

      background: linear-gradient(194.93deg, #65558f 10.52%, #1d1829 118.89%);
    }

    .img-container {
      overflow: hidden;
      margin-top: 1rem;
      transition: height 0.3s ease;
      height: 411px;
      .img-372 {
        height: 411px;
      }

      .img-person {
        filter: grayscale(1);
        transition: filter 0.3s ease, transform 0.3s ease;
      }
    }
    .img-container2 {
      overflow: hidden !important;
      margin-top: 1rem;
      transition: height 0.3s ease;
      height: 411px;

      .img-372 {
        height: 410px;
      }

      .img-person {
        filter: grayscale(1);
        transition: filter 0.3s ease, transform 0.3s ease;
      }
    }

    .text-details {
      padding: 1rem;
      border-top: 0.82px solid #efebeb;
      z-index: 324423;
      .float-right {
        float: right;
      }
      .det1 {
      }

      .det2 {
        display: none; // Hide det2 by default
      }

      /* Initially show det1 and hide det2 */

      h3 {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 600;
        font-size: 21.3657px;
        line-height: 25px;
        /* identical to box height, or 119% */
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.02em;

        color: #ffffff;
      }

      p {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 500;
        font-size: 18.0787px;
        line-height: 25px;
        /* identical to box height, or 164% */
        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #ffffff;

        span {
          img {
            padding-right: 0.5rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ourJourney {
    padding: 0rem;

    .InsideJourney {
      height: unset;
      padding: 1rem;

      h2 {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  .OurPromiseSection {
    height: unset;
    padding: 1rem;

    h1 {
      font-size: 28px;
      line-height: 40px;
    }

    .PromiseDesign {
      margin-top: 1rem;
      height: unset;
      padding: 1rem;
    }
  }

  .chooseVikgole {
    height: unset;
    padding: 1rem;

    h1 {
      font-size: 33px;
      line-height: 40px;
    }

    .ChooseVikgoleDesign {
      padding: 1rem;
      margin-top: 1rem;
      overflow: hidden;

      h3 {
        margin-top: 0rem;
      }
    }
  }
  .MyTeam {
    padding: 1rem;

    .col-md-4 {
      margin-top: 1rem;

      .DesignLayout {
        height: unset;
      }
    }
  }
}
